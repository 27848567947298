import {
	GetUsersSortField,
	PaginatedUsers,
	Role,
	UserOrganizationsRoleStatus,
	UserStatus,
} from 'api/organization';
import { RoleNames } from '@lh/eng-platform-organization-service-rest-client';
import { CurrentUser } from 'types';
import {
	TableColumn,
	TableColumnOption,
} from '../../components/shared/DataTable/DataTable';
import { EmailLink } from '../../components/shared/DataTable/columnComponents/EmailLink';
import { Kebab } from '../../components/shared/DataTable/columnComponents/Kebab';
import { StatusColumn } from '../../components/shared/DataTable/columnComponents/StatusColumn';
import { AvatarName } from '../../components/shared/avatar/AvatarName';
import i18n from '../../i18n';

export type TeamTableData = {
	firstName: string;
	lastName: string;
	email: string;
	id: string;
	role: string;
	avatarUrl?: string;
};
const teamColumns = (
	columnOptions: TableColumnOption<TeamTableData>[],
	currentUser: Partial<CurrentUser>
): TableColumn<TeamTableData>[] => {
	return [
		{
			propertyName: 'name',
			headerDisplay: i18n.t('web.shared.name'),
			sortable: true,
			sortProperty: GetUsersSortField.LastName,
			minWidth: '290px',
			rowColumnComponent: ({ row }) => {
				const rowDataType = row as TeamTableData;
				return (
					<AvatarName
						firstName={rowDataType.firstName}
						lastName={rowDataType.lastName}
						avatarUrl={rowDataType.avatarUrl}
					/>
				);
			},
		},
		{
			propertyName: 'email',
			headerDisplay: i18n.t('web.shared.email'),
			sortable: true,
			sortProperty: GetUsersSortField.Email,
			minWidth: '290px',
			rowColumnComponent: ({ value }) => {
				return <EmailLink emailAddress={value || ''} />;
			},
		},
		{
			propertyName: 'role',
			headerDisplay: i18n.t('web.shared.role'),
			sortable: false,
			minWidth: '206px',
		},
		{
			propertyName: 'status',
			headerDisplay: i18n.t('web.shared.status'),
			sortable: true,
			sortProperty: GetUsersSortField.Status,
			minWidth: '150px',
			rowColumnComponent: ({ value }) => {
				return <StatusColumn status={value} />;
			},
		},
		{
			propertyName: '',
			sortable: false,
			width: '88px',
			minWidth: '88px',
			rowColumnComponent: ({ row, column }) => {
				const rowDataType = row as TeamTableData;
				const isLinusAdmin = !!currentUser.roles?.find((role) =>
					[
						RoleNames.LinusAdministrator.toString(),
						RoleNames.LinusLimitedAdministrator.toString(),
					].includes(role.roleName)
				);
				// We don't want the logged-in user to be able to deactivate/lower their role
				const isCurrentUser = rowDataType.id === currentUser.id;

				return isCurrentUser && !isLinusAdmin ? (
					<></>
				) : (
					<Kebab row={row} column={column} />
				);
			},
			options: columnOptions,
		},
	];
};

/**
 * Now that user can be deactivated on a per organization basis through the research portals, there are 2 statuses to check to determine the user’s overall status:
 * - If a user's global status is "Invited", the status displayed is "Invited".
 * - If a user's global status is "Deactivated", the status displayed is "Deactivated".
 * - If user status is "Active" then -
 *     - Check the user's membership status for the organization and display this status.
 *       The organization level status can be "Active" or "Deactivated".
 * @param userGlobalStatus
 * @param userOrgStatus
 * @returns
 */
export const determineUserStatus = (
	userGlobalStatus: UserStatus,
	userOrgStatus: UserOrganizationsRoleStatus
) => {
	if (userGlobalStatus === UserStatus.Deactivated) {
		return userGlobalStatus;
	}
	if (userGlobalStatus === UserStatus.Invited) {
		if (userOrgStatus === UserOrganizationsRoleStatus.Active) {
			return UserStatus.Invited;
		}
		if (userOrgStatus === UserOrganizationsRoleStatus.Deactivated) {
			return UserStatus.Deactivated;
		}
	}
	if (userGlobalStatus === UserStatus.Active) {
		return userOrgStatus ?? UserOrganizationsRoleStatus.Deactivated;
	}
};

const mapTeamData = (
	currentUser: CurrentUser,
	orgRoles: Role[],
	orgUsers?: PaginatedUsers['results']
): TeamTableData[] => {
	if (!orgUsers) return [];
	return orgUsers.map((orgUser) => {
		const orgRole = orgUser.roles.find(
			(role) => role.organizationId === currentUser.organizationId
		);
		const roleName = (orgRoles || []).find(
			(role) => role.id === orgRole?.roleId
		);
		const userGlobalStatus = orgUser.status;
		const userOrgStatus =
			orgRole?.status ?? UserOrganizationsRoleStatus.Deactivated;
		return {
			...orgUser,
			status: determineUserStatus(userGlobalStatus, userOrgStatus),
			role: i18n.t(roleName?.displayKey || '') || '',
		};
	});
};

export { mapTeamData, teamColumns };
