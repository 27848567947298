import {
	GetUsersSortField as UserSortField,
	GetUsersSortOrder as UserSortOrder,
} from '@lh/eng-platform-organization-service-rest-client';

import { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import {
	GetUsersSortField,
	GetUsersSortOptions,
	GetUsersSortOrder,
	OperationToken,
	PaginatedUsers,
	Role,
	useGetPaginatedUsers,
	useGetRolesByOrgId,
} from 'api/organization';
import { sendEventData } from '../../../analytics/amplitude';
import { UserContext } from '../../../context/UserContext';
import { AnalyticsAction } from '../../../enums/analyticsAction';
import { icons } from '../../../enums/icons';
import { SortDir } from '../../../generated/graphql';
import i18n from '../../../i18n';
import {
	TeamTableData,
	mapTeamData,
	teamColumns,
} from '../../../schemas/table/teamSchema';
import { LinusPaginatedDataTable } from '../../shared/DataTable/PaginatedDataTable';
import { Header } from '../../shared/Header';
import { LinusModal } from '../../shared/LinusModal';
import { useActiveTableHeader } from '../../shared/hooks';
import { AddNewUserForm } from '../addNewUserForm';
import { EditUserForm } from '../editUserForm';
import { getPaginatedUsersQueryFilterOptions } from './Team.helpers';
import { CurrentUser } from 'types';

const ROWS_PER_PAGE = 10;

const Team = (): JSX.Element | null => {
	const theme = useTheme();
	const { t } = useTranslation();
	useEffect(() => {
		sendEventData({ eventType: AnalyticsAction.ViewedOrganization });
	}, []);

	const { currentUser } = useContext(UserContext);

	const [activeData, setActiveData] = useState<PaginatedUsers>();
	const [isAddTeamMemberOpen, setIsAddTeamMemberOpen] = useState(false);
	const [isEditTeamMemberOpen, setIsEditTeamMemberOpen] = useState(false);
	const [selectedMember, setSelectedMember] = useState('');
	const [page, setPage] = useState(1);
	const [teamData, setTeamData] = useState<TeamTableData[]>([]);
	const [sort, setSort] = useState<GetUsersSortOptions | null>({
		sortField: [UserSortField.CreatedAt],
		sortOrder: [UserSortOrder.Desc],
	});
	const [search, setSearch] = useState<string>('');

	const {
		data,
		refetch: fetchUsers,
		isFetching,
	} = useGetPaginatedUsers({
		organizationId: currentUser?.organizationId,
		page: page - 1,
		pageSize: ROWS_PER_PAGE,
		...sort,
		...getPaginatedUsersQueryFilterOptions(search),
	});

	const { data: roles } = useGetRolesByOrgId({
		organizationId: currentUser.organizationId,
	});

	const isSelectedMemberThirdParty = useRef(false);

	useEffect(() => {
		if (data) {
			setActiveData(data);
		}
	}, [data]);

	const { activeHeader, setActiveHeader } = useActiveTableHeader();

	const toggleAddTeamMemberModal = (): void => {
		setIsAddTeamMemberOpen(!isAddTeamMemberOpen);
	};
	const closeEditTeamMember = (): void => {
		setIsEditTeamMemberOpen(!isEditTeamMemberOpen);
		setSelectedMember('');
		fetchUsers();
		isSelectedMemberThirdParty.current = false;
	};
	const viewEditUserModal = ({ id }: TeamTableData): void => {
		const selectedUser = data?.results.find((user) => user.id === id);

		isSelectedMemberThirdParty.current =
			selectedUser?.isThirdPartyManaged ?? false;
		setIsEditTeamMemberOpen(!isEditTeamMemberOpen);
		setSelectedMember(id);
	};

	const handleModalFinished = (): void => {
		setIsEditTeamMemberOpen(false);
		setIsAddTeamMemberOpen(false);
		setSelectedMember('');
		fetchUsers();
	};

	const count = activeData?.results?.length || 0;
	const total = activeData?.totalCount || 0;

	useEffect(() => {
		setTeamData(
			mapTeamData(currentUser, roles as Role[], activeData?.results)
		);
	}, [activeData, currentUser, roles]);

	const partialUser: Partial<CurrentUser> = useMemo(() => {
		return {
			id: currentUser.id,
			roles: currentUser.roles,
		};
	}, [currentUser.id, currentUser.roles]);

	const tableColumns = teamColumns(
		[
			{
				key: 'viewUser',
				value: t`web.team.viewUserButton`,
				callback: viewEditUserModal,
				operations: [OperationToken.EditUser],
			},
		],
		partialUser
	);

	const notFoundTitle = i18n.t('web.shared.search.noMatchFound', {
		entity: t('web.team.table.users').toLowerCase(),
	});
	const notFoundSubtitle = i18n.t(
		'web.shared.search.addNewOrChangeSpelling',
		{
			entity: t('web.team.table.user').toLowerCase(),
		}
	);

	const onSort = (dir: SortDir | undefined, prop: string) => {
		if (!dir) {
			setSort(null);
		} else {
			setActiveHeader(prop);
			setSort({
				sortField: [prop as GetUsersSortField],
				sortOrder: [dir as GetUsersSortOrder],
			});
		}
	};

	return (
		<>
			<Header />
			<LinusPaginatedDataTable
				title={t`web.team.table.title`}
				columns={tableColumns}
				tableData={teamData}
				rowsPerPage={ROWS_PER_PAGE}
				buttonText={t`web.team.addNewUserButton`}
				searchBarPlaceholder={t`web.shared.search.byFirstOrLastName`}
				notFoundTitle={notFoundTitle}
				notFoundSubtitle={notFoundSubtitle}
				count={count}
				total={total}
				currentPage={page}
				setCurrentPage={(pageNumber: number) => setPage(pageNumber)}
				loading={isFetching}
				hasInitialData={false}
				onSort={onSort}
				onFilter={(predicate: string) => setSearch(predicate)}
				onHeaderButtonClick={toggleAddTeamMemberModal}
				operations={[OperationToken.InviteUser]}
				buttonIcon={icons.AddUserOutlined}
				data-testid='add-new-user'
				noDataIcon={icons.NoDataUser}
				activeHeader={activeHeader}
			/>

			{isAddTeamMemberOpen && (
				<LinusModal
					onClose={toggleAddTeamMemberModal}
					titleIcon={icons.AddUserSolid}
					titleIconColor={theme.color.iconAddUserSolid}
					title={t`web.team.addNewUserModal.title`}
					subTitle={t`web.team.addNewUserModal.description`}>
					<AddNewUserForm
						roles={roles}
						onCancel={toggleAddTeamMemberModal}
						onFinish={handleModalFinished}
					/>
				</LinusModal>
			)}
			{isEditTeamMemberOpen && (
				<LinusModal
					onClose={closeEditTeamMember}
					titleIcon={icons.UserSolid}
					titleIconColor={theme.color.iconAddUserSolid}
					title={t`web.team.editUserModal.title`}
					isThirdPartyManaged={isSelectedMemberThirdParty.current}>
					<EditUserForm
						roles={roles}
						userId={selectedMember}
						onCancel={closeEditTeamMember}
						onFinish={handleModalFinished}
					/>
				</LinusModal>
			)}
		</>
	);
};

export { Team };
